@import './scorecardWidgets.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div, span, p, a {
  font-family: 'General Sans', sans-serif;
}

@media screen and (max-width: 920px) {
  .web-view {
    display: none !important;
  }
}

@media (min-width: 921px) {
  .mob-view {
    display: none !important;
  }
}

.multi-remove-icon {
  rect {
    fill: #c37dd2;
  }
  path {
    fill: #fff;
  }
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.disabled {
  cursor: not-allowed;
  opacity: .5;
}

.header-clip {
  clip-path: polygon(0% 0,99% 0,88% 100%,0 100%);
}
.header-slanting-line {
  clip-path: polygon(80% 0,100% 0,20% 100%,0 100%);
}
.suspended-market-info {
  right: 20px;
  @media screen and (min-width: 920px) {
    right: 67px;
  }
}

.inline-betslip {
  background-color: #fae4fb;
  td {
    padding: 0 8px;
  }
}
.signup-btn-clip {
  clip-path: polygon(15% 0%,100% 0,100% 100%,0 100%);
}

.lay-btn-clip {
  clip-path: polygon(25% 0%, 100% 0, 100% 100%, 0 100%);
}

.back-btn-clip {
  clip-path: polygon(0% 0%, 100% 0, 74% 100%, 0 100%);
}

.middle-btn-clip {
  clip-path: polygon(0% 0%, 100% 0, 74% 100%, 0 100%);
}