@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* For chrome, safari, opera */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.animate-blink {
  animation: blink 1s infinite;
}


.marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
  }
  
  .marquee-content {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 10s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }


  
  
