.premium-ctn {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Default layout for 3 or fewer items */
  .premium-ctn > .premium-item {
    flex: 1 1 calc(33.33% - 10px); /* 3 items per row with equal width */
  }
  
  /* Special case for more than 3 items */
  .premium-ctn > .premium-item:nth-child(n+4) {
    flex: 1 1 calc(50% - 10px); /* 2 items per row */
  }
  
  .premium-ctn-more > .premium-item {
    flex: 1 1 calc(50%); /* 2 items per row */
  }

  @media screen and (max-width: 920px) {
    .premium-ctn > .premium-item {
      flex: 1 1 100%; /* 3 items per row with equal width */
    }
    .premium-ctn-more > .premium-item {
      flex: 1 1 100%; /* 2 items per row */
    }
  }

  .widgets .sr-widget {
    background-color: #151212;
  }